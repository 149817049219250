<template>
    <item-card>
        <template v-slot:title>
            <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                Izrakstīt kredītrēķinu
            </h3>
        </template>

        <template v-slot:buttons>
            <Button icon="close" @click="hideForm"/>
        </template>

        <template v-slot:content>
            <form>

                <div class="grid grid-cols-1 sm:grid-cols-4 lg:grid-cols-12 gap-6">

                    <div class="col-span-1 sm:col-span-4 lg:col-span-4">
                        <Input type="date" name="creditNoteDate" placeholder="Atgriešanas datums" v-model="form.creditNoteDate"
                               :errors="errors.creditNoteDate"/>
                    </div>

                    <div class="col-span-1 sm:col-span-2 lg:col-span-2">
                        <div class="flex h-full items-end">
                            <Radio label="Valoda:" name="lang" v-model="form.lang"
                                   :items="[{name: 'LV', value: 'lv'}, {name: 'ENG', value: 'en'}]"
                                   :errors="errors.lang"/>
                        </div>
                    </div>

                    <div class="col-span-1 sm:col-span-2 lg:col-span-2">
                        <div class="flex h-full items-end">
                            <Checkbox text="Sagat. elektroniski" name="electronic_document"
                                      v-model="form.electronic_document"
                                      :errors="errors.electronic_document"/>
                        </div>
                    </div>

                    <div class="col-span-1 sm:col-span-2 lg:col-span-2">
                        <div class="flex h-full items-end">
                            <Checkbox text="Reversais PVN" name="reverse_vat" v-model="form.reverse_vat"
                                      :errors="errors.reverse_vat"/>
                        </div>
                    </div>

                    <div class="col-span-1 sm:col-span-2 lg:col-span-2">
                        <div class="flex h-full items-end">
                            <Checkbox text="Atgriezt preces" name="revert_stock" @change="resetItems"
                                      v-model="form.revert_stock"
                                      :errors="errors.revert_stock"/>
                        </div>
                    </div>

                </div>

                <div v-if="form.revert_stock" class="mt-8 mb-8">

                    <Autocomplete placeholder="Meklēt pēc IMEI / Serial nr. / Kods" v-model="selectedUniqueItem"
                                  :errors="errors.supplier"
                                  :items="allOrderItems"
                                  :showItems="true"
                                  :clear-on-click="true"
                                  class="mb-10"
                    />

                    <SingleCreditNoteItemForm v-for="item in creditNoteItems" :item="item" :key="item.custom_uuid"
                                              @remove-item="removeCreditNoteItem(item)"/>
                </div>

                <div v-if="!form.revert_stock" class="">
                    <div class="mt-6 mb-0 flex justify-end">
                        <button @click="addBlankPosition" type="button" class="inline-flex items-center px-3 py-2 border
                        border-transparent text-sm leading-4 font-medium rounded-md text-white
                        bg-gray-500 hover:bg-gray-600 transition duration-150 ease-out hover:ease-in mb-3 ml-auto"
                        >
                            Pievienot pozīciju
                        </button>
                    </div>

                    <SingleCreditNoteItemForm v-for="item in creditNoteItems" :item="item" :key="item.custom_uuid"
                                              @remove-item="removeCreditNoteItem(item)"/>
                </div>

                <div class="pt-5">
                    <div class="flex justify-end">
                        <button @click="hideForm" type="button" class="bg-white dark:bg-gray-750 py-2 px-4 border
                        border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700
                        dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2">
                            Atcelt
                        </button>

                        <template v-if="creditNoteItems.length > 0 ">
                            <template v-if="!loading">
                                <button type="button" @click="save" class="flex justify-center py-2 px-4 border border-transparent rounded-md
                                shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to
                                hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition
                                duration-500 ease-in-out">
                                    Saglabāt
                                </button>
                            </template>
                            <template v-else>
                                <Loading/>
                            </template>
                        </template>
                    </div>
                </div>

            </form>
        </template>
    </item-card>
</template>

<script>

import ItemCard from "@/components/Components/ItemCard"
import Input from "@/components/Components/Input";
import Radio from "@/components/Components/Radio";
import Checkbox from "../../Components/Checkbox";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";
import Autocomplete from "../../Components/Autocomplete";

import SingleCreditNoteItemForm from "./SingleCreditNoteItemForm";

import {mapGetters} from "vuex";
import {v4 as uuidv4} from "uuid";
import {or} from "@vueuse/core";

export default {
    name: "CreateCreditNote",
    components: {
        ItemCard,
        Input,
        Radio,
        Checkbox,
        SingleCreditNoteItemForm,
        Loading,
        Autocomplete

    },
    props: ['order'],
    data() {
        return {
            selectedUniqueItem: null,
            form: {
                creditNoteDate: new Date().toISOString().substr(0, 10),
                electronic_document: true,
                reverse_vat: false,
                revert_stock: true,
                lang: 'lv',
            },
            creditNoteItems: [],
            allOrderItems: [],
        }
    },
    mounted() {
       this.setOrderUniqueItems()
    },
    computed: {
        ...mapGetters({
            formsForDisplay: 'formsForDisplay',
            loading: 'loading',
            errors: "errors",
        }),
    },
    watch: {
        selectedUniqueItem() {
            if (this.selectedUniqueItem) {
                this.addSelectedUniqueItem()
            }
        },
    },
    methods: {
        setOrderUniqueItems(){

            this.allOrderItems = []

            const selectedCreditNoteItemIds = this.creditNoteItems.map(creditNoteItem => {
                return creditNoteItem.unit_id
            })

            this.order.order_items.forEach(orderItem => {
                orderItem.unique_items.forEach(uniqueItem => {

                    if(!uniqueItem.credit_note) {

                        const item = {
                            id: uniqueItem.id,
                            name: uniqueItem.number + ' / ' + orderItem.orderable.name,
                            unit_id: uniqueItem.id,
                            description: orderItem.orderable.name,
                            code: uniqueItem.number,
                            order_item_id: orderItem.id
                        }

                        if (!selectedCreditNoteItemIds.includes(item.unit_id)) {
                            this.allOrderItems.push(item)
                        }
                    }
                })
            })
        },
        resetItems() {
            this.creditNoteItems = []

            if (!this.form.revert_stock) {
                this.addBlankPosition()
            }
        },

        addSelectedUniqueItem() {
            this.creditNoteItems.push({
                description: this.selectedUniqueItem.description,
                code: this.selectedUniqueItem.code,
                order_item_id: this.selectedUniqueItem.order_item_id,
                unit_id: this.selectedUniqueItem.unit_id,
                custom_uuid: uuidv4(),
                price: '',
                quantity: 1,
                total: '',
                vatRate: 0,
                totalVat: '',
                totalWithVat: '',
                disableQuantity: true
            })

            this.selectedUniqueItem = null
            this.setOrderUniqueItems()
        },
        addBlankPosition() {
            this.creditNoteItems.push({
                description: '',
                code: '',
                custom_uuid: uuidv4(),
                price: '',
                quantity: 1,
                total: '',
                vatRate: 0,
                totalVat: '',
                totalWithVat: '',
                disableQuantity: false
            })

        },
        removeCreditNoteItem(item) {
            this.creditNoteItems = this.creditNoteItems.filter(creditNoteItem => {
                return creditNoteItem.custom_uuid !== item.custom_uuid
            })

            this.setOrderUniqueItems()
        },
        hideForm() {
            this.$store.dispatch('removeAllFormsForDisplay')
        },
        formatCreditNoteItems(){
            return this.creditNoteItems.map(creditNoteItem => {
                return {
                    order_item_id : creditNoteItem.order_item_id,
                    unit_id : creditNoteItem.unit_id,
                    code : creditNoteItem.code,
                    description : creditNoteItem.description,
                    price : creditNoteItem.price,
                    quantity : creditNoteItem.quantity,
                    vat_rate : creditNoteItem.vatRate
                }
            })
        },
        save() {
            this.$Progress.start()

            this.$store.dispatch('createNewCreditNote', {
                data: {
                    date: this.form.creditNoteDate,
                    reverse_vat: this.form.reverse_vat,
                    lang: this.form.lang,
                    revert_stock: this.form.revert_stock,
                    electronic_document: this.form.electronic_document,
                    credit_note_items: this.formatCreditNoteItems(),
                },
                order_id: this.order.id
            }).catch(error => {
                this.$Progress.fail()
            })
        }
    }
}
</script>

<style scoped>

</style>