<template>

    <div v-if="form" class="block hover:bg-gray-50 dark:hover:bg-gray-770 border-t border-gray-200 dark:border-gray-700 w-full mt-4">
        <div class="px-4 py-4 sm:px-6">
            <div class="flex">

                <div class="flex flex-wrap">

                    <Input name="name" class="w-3/5" placeholder="Preces nosaukums" v-model="form.description" />
                    <Input name="sku" class="ml-2" placeholder="Preces kods" v-model="form.code" />

                    <div class="w-full flex flex-wrap gap-3 mt-4">
                        <div class="w-44">
                            <Input type="number" name="price" placeholder="Cena bez PVN" v-model="form.price" @change="calculateTotals" :errors="errors[`price-${form.id}`]" />
                        </div>


                        <div class="w-44">
                            <Input type="number" :disabled="form.disableQuantity" name="quantity" placeholder="Skaits" v-model="form.quantity" @change="calculateTotals" :errors="errors[`quantity-${form.id}`]" />
                        </div>

                        <div class="w-44">
                            <Input type="number" name="vatRate" placeholder="PVN %" v-model="form.vatRate" @change="calculateTotals" />
                        </div>

                        <div class="36">
                            <ItemText title="Summa(bez PVN) EUR" :text="item.total" />
                        </div>
                        <div class="36">
                            <ItemText title="PVN EUR" :text="item.totalVat" />
                        </div>
                        <div class="w-44">
                            <ItemText title="Summa(ar PVN) EUR" :text="item.totalWithVat" />
                        </div>

                    </div>


                </div>

                <div class="ml-auto">
                    <p class="mt-2 flex items-center text-sm font-semibold text-gray-900 dark:text-gray-300 sm:mt-0">
                        <Button icon="close" @click="$emit('remove-item', item)" />
                    </p>
                </div>
            </div>
        </div>
    </div>


</template>

<script>

import Calculations from "../../../services/Calculations/calculations";
import Input from "@/components/Components/Input";
import ItemText from "@/components/Components/ItemText";
import {mapGetters} from "vuex";

export default {
    name: "SingleCreditNoteItemForm",
    components: {
        ItemText,
        Input,
    },
    props: ['item'],
    data: () => ({
        total: 0,
        form: null,
    }),
    computed: {
        ...mapGetters({
            loading: "loading",
            errors: "errors",
        }),
    },
    mounted() {
        this.form = this.item
        this.calculateTotals()
    },
    methods: {
        changeQuantity() {
            this.calculateTotals()
        },
        calculateTotals() {
            this.form.total = (this.form.price * this.form.quantity).toFixed(2)
            this.form.totalVat = (Calculations.getVat(this.form.total, this.form.vatRate)).toFixed(2)
            this.form.totalWithVat = ((this.form.total * 1)  + +this.form.totalVat).toFixed(2)
        },
    }
}
</script>

<style scoped>

</style>